var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('BaseTableFiltered',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.courses,"search":_vm.search,"filter":_vm.filter,"sort-by":"id","sort-desc":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"},attrs:{"data-cy":"titleCourse"}},[_vm._v("Kurs")]),_c('v-spacer'),_c('v-text-field',{attrs:{"data-cy":"searchCourseList","append-icon":"mdi-magnify","label":"Søk i kurs","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Nytt kurs","icon":"mdi-plus","dataCy":"newButton"},on:{"handleClick":function($event){return _vm.$router.push("/kurs/ny")}}})]},proxy:true},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"openCourse"},on:{"click":function($event){return _vm.navigateToCoursePage(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,true)},[_c('span',[_vm._v("Åpne kurs")])]),(!_vm.hasMemberOrgAccess)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"editCourse"},on:{"click":function($event){return _vm.navigateToEditCourse(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Rediger kurs")])]):_vm._e()]}},{key:"item.participantsCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item ? _vm.getParticipantsCount(item.participants) : 0)+" ")]}},{key:"item.startDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatDateShort(value) : "")+" ")]}},{key:"item.endDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatDateShort(value) : "")+" ")]}},{key:"item.enrollmentDeadline",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatDateShort(value) : "")+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }