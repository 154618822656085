






























































import {
  ApiGetShortCourseDto,
  ApiGetCourseParticipantDtoType,
  ApiGetShortCourseWithParticipantsDto,
  ApiGetPureCourseParticipantDto,
} from "@/api/generated/Api";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { CourseResponsibleFilter } from "@/shared/enums/courseResponsibleFilter.enum";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ParticipantEnrollmentStatus } from "@/shared/enums/ParticipantEnrollmentStatus.enum";
import { ParticipantRoles } from "@/shared/enums/ParticipantRoles.enum";
import { CourseRouteNames } from "@/shared/enums/RouteNames/courseRouteNames.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { hasMemberOrgAccess, useRestrictedAccessApi } from "@/shared/helpers/accessLevelApiAdapter";
import { formatDateShort } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRouter } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseListPage",
  components: {
    BaseTooltipIconButton,
    BaseTableFiltered,
  },
  setup() {
    const router = useRouter();
    const restrictedAccessApi = useRestrictedAccessApi();

    const courses = ref<ApiGetShortCourseDto[] | ApiGetShortCourseWithParticipantsDto[]>();
    const search = ref<string>("");
    const filter = ref([]);

    const navigateToEditCourse = (courseId: number) => {
      router.push({
        name: CourseRouteNames.EditCourse,
        params: {
          editCourseId: courseId.toString(),
        },
      });
    };

    const navigateToCoursePage = (courseId: number) => {
      router.push({
        name: SingleCourseRouteNames.CourseDashboard,
        params: {
          id: courseId.toString(),
        },
      });
    };

    const getCourses = async () =>
      (
        await restrictedAccessApi.getCourses({
          CourseResponsibleFilter: CourseResponsibleFilter.All,
        })
      ).data;

    const getParticipantsCount = (participants?: ApiGetCourseParticipantDtoType[] | ApiGetPureCourseParticipantDto[]) =>
      participants?.filter(
        (p) => p.roleName === ParticipantRoles.Student && p.status === ParticipantEnrollmentStatus.Enrolled
      )?.length ?? 0;

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        courses.value = await getCourses();
      });
    });

    return {
      courses,
      navigateToEditCourse,
      navigateToCoursePage,
      headers,
      filter,
      search,
      hasMemberOrgAccess,
      formatDateShort,
      getParticipantsCount,
    };
  },
});

const headers = [
  { text: "ID", value: "id" },
  { text: "Produktnr", value: "erpProductNo" },
  { text: "Kursnavn", value: "courseName" },
  { text: "Sted", value: "areaName" },
  {
    text: "Arrangør",
    value: "organizerOrganizationName",
    filter: true,
  },
  { text: "Oppstart", value: "startDate" },
  { text: "Slutt", value: "endDate" },
  {
    text: "Status",
    value: "status",
    filter: true,
  },
  {
    text: "Påmeldt",
    value: "participantsCount",
  },
  { text: "Påmeldingsfrist", value: "enrollmentDeadline" },
  { text: "Undervisningsform", value: "teachingMethod", filter: true },
  { text: "Ansvarlig", value: "courseSupervisorName", filter: true },
  { text: "Administrator", value: "lecturerName", filter: true },
  { text: "Handlinger", value: "actions", sortable: false },
];
